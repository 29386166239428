import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Panel } from 'react-bootstrap'
import SearchDictionaryFilter from './SearchDictionaryFilter'
import OmnisalesFilter from './OmnisalesFilter'

class SearchFilterContainer extends Component {
  static propTypes = {
    brandFilters: PropTypes.object,
    categoryFilters: PropTypes.object,
    scoreCard: PropTypes.string,
    userTertile: PropTypes.string,
    dataImpactFilter: PropTypes.string,
    onSearchFilterChanged: PropTypes.func,
    brandUpdateUsers: PropTypes.array,
    categoryUpdateUsers: PropTypes.array,
    selectedBrandUsers: PropTypes.array,
    selectedCategoryUsers: PropTypes.array
  }

  render() {
    return (
      <div className="search-filters">
        <Panel id="collapsible-panel-example-2">
          <Panel.Heading>
            <Panel.Title toggle>Search Filters</Panel.Title>
          </Panel.Heading>
          <Panel.Collapse>
            <Panel.Body>
              <SearchDictionaryFilter
                brandFilters={this.props.brandFilters}
                categoryFilters={this.props.categoryFilters}
                scoreCard={this.props.scoreCard}
                userTertile={this.props.userTertile}
                dataImpactFilter={this.props.dataImpactFilter}
                onChanged={this.props.onSearchFilterChanged}
                brandUpdateUsers={this.props.brandUpdateUsers}
                categoryUpdateUsers={this.props.categoryUpdateUsers}
                selectedBrandUsers={this.props.selectedBrandUsers}
                selectedCategoryUsers={this.props.selectedCategoryUsers}
              />
              <OmnisalesFilter />
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}

export default SearchFilterContainer
